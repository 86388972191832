<template>
  <div>
    <b-tabs v-show="shopeeToken!=null">
      <b-tab title="資料匯入" v-show="shopeeToken!=null">
          <b-card>
            <b-overlay :show="show" rounded="sm">
              <b-row>
                <b-col :cols="2">
                  <img :src="stpngSrc" alt="Logo" style="height:40px;"/>
                </b-col>
                <b-col :cols="7">
                  <b-form-group label="ShopStore訂單上傳區:" label-for="file-small" label-cols-sm="4">
                    <b-form-file browse-text="選擇訂單Excel檔" id="orderInv" 
                      v-model="fileStOrder" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                      placeholder="檔案拖移此處" drop-placeholder="Drop file here..." />
                  </b-form-group>
                </b-col>
                <b-col :cols="3">
                  <b-button variant="success" @click="uploadStOrderXlsx()">上傳</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col :cols="2"></b-col>
                <b-col :cols="3">
                  <b-form-checkbox checked :disabled="true" value="Y" v-model="paperPrintCheckbox" v-show="paperPrint == 'Y'">
                    已啟用紙本發票
                  </b-form-checkbox>
                  <b-form-checkbox checked :disabled="true" value="N" v-model="paperPrintCheckbox" v-show="paperPrint == 'N'">
                    未啟用紙本發票
                  </b-form-checkbox>
                </b-col>
                <b-col :cols="4">
                  <b-alert variant="warning" show v-show="paperPrint == 'N'">
                      <div class="alert-body">
                        <span>如需使用紙本發票列印功能請洽管理員</span>
                      </div>
                  </b-alert>
                </b-col>
              </b-row>
            </b-overlay>
          </b-card>
          <b-row>
            <!-- <b-col cols="12">
              <b-card title="ShopStore訂單下載教學">
                <img :src="stpngSrct1" style="height:40px;" @click="tourShow = true"/>
                <img :src="stpngSrct2" style="height:40px;margin-left:30px;" @click="tourShow = true"/>
              </b-card>
            </b-col> -->
            <b-col cols="12">
              <b-card title="最近上傳5筆">
                <b-table responsive="sm" :items="xlsHistory" :fields="uploadHistoryFields">
                  <template #cell(invQty)="data">
                      <div class="text-right" style="margin-right:10px;">{{data.item.invQty}}</div>
                  </template>
                  <template #cell(price)="data">
                      <div class="text-right" style="margin-right:10px;">{{data.item.price}}</div>
                  </template>
                  <template #cell(tax)="data">
                      <div class="text-right" style="margin-right:10px;">{{data.item.tax}}</div>
                  </template>
                  <template #cell(fileName)="data">
                    <a href="#" target="_self" style="font-size:12px;" 
                        @click="downloadHistory(data.item.rowId,data.item.fileName)" 
                        >{{data.item.fileName}}</a> 
                  </template>
                </b-table>
              </b-card>
            </b-col>
          </b-row>
      </b-tab>
      <!-- <b-tab title="訂單資料" v-show="shopeeToken!=null">
        <b-row>
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col cols="4">
                  <b-form-group label-cols="3" content-cols="9" label="訂單編號" label-for="qGetDate">
                    <b-form-input id="qGetDate" v-model="q.qOrderNo"/>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label-cols="3" content-cols="9" label="發票號碼" label-for="qGetDate">
                    <b-form-input id="qGetDate" v-model="q.qInvNo"/>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-button type="submit" class="mr-1" variant="primary" @click="queryInv(1)">查詢</b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="9">
            <b-table responsive="sm" :items="orderTableData" :fields="fields">
            <template #cell(orderDate)="data">
              <div>{{data.item.orderDate.create}}</div>
              <div>{{data.item.orderDate.finish}}</div>
            </template>
            <template #cell(editId)="data">
              <b-button @click="queryInvDetail(data.item)" size="sm">查</b-button>
            </template>
            </b-table>
          </b-col>發票明細
          <b-col cols="3">
            <b-card>
              <b-overlay rounded="sm">
                訂單明細
                <table>
                  <tr><td>序號</td><td>名稱</td><td>數量</td><td>金額</td></tr>
                  <tr v-for="i in invDetails" :key="i.rowId"><td>{{i.seq}}</td><td>{{i.prodName}}</td><td style="text-align:right">{{i.qty}}</td><td style="text-align:right">{{i.amt}}</td></tr>
                </table>
              </b-overlay>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="pageSize" 
              @page-click="pageClick" class="mb-1"  :limit="10"/>
          </b-col>
        </b-row>
      </b-tab> -->
    </b-tabs>
    <!-- <b-modal v-model="tourShow" centered title="奇摩格式教學" size="xl"  @shown="focusMyElement">
      <div>
        <swiper class="swiper-navigations" :options="swiperOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
          <swiper-slide v-for="data in swiperData" :key="data.img">
            <b-img :src="data.img" fluid/>
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination"/>
          <div slot="button-next" class="swiper-button-next"/>
          <div slot="button-prev" class="swiper-button-prev"/>
        </swiper>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right" @click="tourShow=false"> 關閉</b-button>
        </div>
      </template>
    </b-modal> -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {
  BCard, BCardText, BCol, BRow, BFormGroup, BFormInput, BButton,BTab,BTabs,BFormFile,BOverlay,BTable,BPagination,BFormCheckbox,BImg,BAlert,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import vSelect from 'vue-select'
import store from '@/store'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Swiper,SwiperSlide,
    BCol,
    BTab,
    BTabs,
    BRow,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,BTable,BPagination,BImg,BFormCheckbox,BAlert,
    vSelect,
    BFormFile,
  },
  data() {
    return {
      paperPrint: 'N',
      paperPrintCheckbox: ['Y'],

      // tourShow:false,
      // swiperOptions: {
      //   spaceBetween: 30,
      //   centeredSlides: true,
      //   pagination: {
      //     el: '.swiper-pagination',
      //     clickable: true,
      //   },

      //   navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev',
      //   },
      // },
      // swiperData: [
      // ],
      shopeeToken: null,
      currentPage :1,
      pageSize :12,
      rows: 0,
      xlsHistory: [],
      show: false,
      tableBasic: [],
      name: '',
      taxType: null,
      taxTypeOptions: [{value: '1', text: '應稅'}],
      q: {
        qInvNo: '',
        qOrderNo: '',
        qCusNo:'',
      },
      fileStOrder: null,
      token: null,
      cusNo: '',
      tab3UploadPrice: 0,
      tab3UploadTax: 0,
      tab3UploadCreate: 0,
      tab3UploadExistInvoiceQty:0,
      tab3UploadReturnInvoiceQty:0,
      tab3UploadReturnPrice: 0,
      tab3UploadExistOrderQty: 0,
      user: {
        cusNo: '',
        userId: '',
      },
      selectRowRtOrder:null,
      orderTableData:[],
      invDetails: [],
      uploadHistoryFields: [
        {key: 'fileName', label: '檔名'},
        {key: 'uploadDateDisplay', label: '上傳日期'},
        {key: 'invQty', label: '發票開立張數'},
        // {key: 'price', label: '發票銷售額'},
        // {key: 'tax', label: '發票稅額'},
        {key: 'finishIssueDateDisplay', label: '完成開立日期'},
      ],
      fields: [
        {key: 'orderNo', label: '單號'},
        {key: 'flow', label: '狀態'},
        {key: 'invNo', label: '發票號碼'},
        {key: 'invCreateDate', label: '發票開立日期'},
        {key: 'tax', label: '稅額'},
        {key: 'totalPrice', label: '金額'},
        {key: 'orderDate', label: '建立/完成日期'},
        {key: 'uploadDate', label: '上傳日期'},
        {key: 'editId', label: '明細'},
      ],
      jsonFileName:'',
      stpngSrc:process.env.BASE_URL + '/ordertype/st.png',
      // stpngSrct1:process.env.BASE_URL + '/ordertype/sa-tour-1.png',
    }
  },
  created() {
    let userData = { ability: [
      {action:'shopee',subject:'Auth'},
      // {action:'basic',subject:'Auth'},
    ]}
    this.$ability.update(userData.ability)
    // 從session來的
    let se = JSON.parse(sessionStorage.getItem('se'))
    if(se==null){
      return
    }
    this.shopeeToken = se.shopeeToken
    this.user.cusNo = se.cusNo
    this.user.userId = se.accountId
    this.q.qCusNo = se.cusNo
    this.paperPrint = se.paperPrint

    this.getLastUploadTitle()
  },
  methods: {
    parseDate(d){
      if(d!=null && d.length >= 12){
        return d.substring(4,6) + '/' +d.substring(6,8) + ' ' +d.substring(8,10) + ':'+d.substring(10,12) 
      }
      return ''
    },
    getFormatDate(str ,type){
      if(type == 'mm/dd hh:mm') {
        if( str!= null) {
          return str.substring(4,6)+'/'+str.substring(6,8)+' '+ str.substring(8,10)+':'+str.substring(10,12)
        }
      } else if(type == 'yyyy/mm/dd hh:mm') {
        if(str!= null) {
          return str.substring(0,4)+'/'+str.substring(4,6)+'/'+str.substring(6,8)+' '+ str.substring(8,10)+':'+str.substring(10,12)
        }
      }
    },
    getLastUploadTitle(){
      let params = {
        shopeeToken: this.shopeeToken,
      }
      this.$http.get('../api/shopstore/getTitleInfo', { params }).then(res => {
        res.data.data.forEach(e => {
          e.uploadDateDisplay = this.getFormatDate(e.uploadDate,'yyyy/mm/dd hh:mm')
          e.finishIssueDateDisplay = this.getFormatDate(e.finishIssueDate,'yyyy/mm/dd hh:mm')
          if(e.issueInvFlag == 'N' ) {
            e.finishIssueDateDisplay = '開立中'
            e.invQty = '開立中'
          }
        })
        this.xlsHistory = res.data.data
      })
    },
    downloadHistory (rowId,orgFileName) {
      axios({
          url: '../api/shopstore/shopstoreFile/' + rowId,
          method: 'GET',
          responseType: 'blob',
      }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', orgFileName); 
          document.body.appendChild(link);
          link.click();
      });
    },
    uploadStOrderXlsx(){
      // if(true){ return }
      if(this.fileStOrder == null) {
        this.$bvToast.toast('錯誤', {
          title: `請選擇要上傳的檔案`,
          toaster: 'b-toaster-top-center' ,
          autoHideDelay: 1000,
          variant: 'danger',
          solid: false,
          appendToast: false,
        })
        return
      }
      
      let headers = {
        'Accpet': 'application/json',
        'content-type' : 'multipart/form-data',
        Authorization: this.token,
      }
      let user = store.state.commonParam.user
      this.formData = new FormData()
      this.formData.append('fileStOrder', this.fileStOrder)
      this.formData.append('shopeeToken', this.shopeeToken)
      this.formData.append('paperPrint', this.paperPrint)

      this.show = true
      this.$http.post('../api/shopstore/uploadStOrderXlsx', this.formData, {headers}).then(res => {
        if(res.data.msg == 'success') {
          this.fileStOrder  = null
          this.show = false
          this.tab3UploadPrice = res.data.data.price
          this.tab3UploadTax= res.data.data.tax
          this.tab3UploadCreate = res.data.data.create
          this.tab3UploadExistOrderQty = res.data.data.existOrderQty
          this.getLastUploadTitle()
          this.$toast({ component: ToastificationContent,
              props: {title: '上傳完成', variant: 'success', autoHideDelay: 2000, icon:'none'},
            })        
        } else {
          this.show = false
          this.$toast({ component: ToastificationContent,
              props: {title: res.data.data, variant: 'danger', autoHideDelay: 5000, icon:'none'},
            })        
        }
      }).catch(() =>{
        this.show = false
        this.$bvToast.toast('錯誤', {
          title: `上傳錯誤`,
          toaster: 'b-toaster-top-center' ,
          autoHideDelay: 5000,
          variant: 'danger',
          solid: false,
          appendToast: false,
        })
      })
    },
    toast(toaster, append = false) {
      this.counter += 1
      this.$bvToast.toast('更新成功', {
        title: `Toaster ${toaster}`,
        toaster,
        variant: 'success',
        solid: false,
        appendToast: append,
      })
    },
  },
  computed:{
  }
}
</script>

<style lang="scss" >
@import '~@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@/assets/scss/style.scss';

.history-table{
  border: 1px solid white;
  border-collapse: collapse;
}
// .dark-layout label {
//     font-size: 16px;
// }
</style>
